<template>
	<DashCard
		title="Leading Others"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Accelerate: Leading Others"
		style="min-height:100%;"
		@reload="$emit('reload', 'accelerate_leading_others')"
	>
		<template #icon><Icon /></template>

		<template #noData>
			<div style="max-width:400px">
				Data will be displayed once the 4 survey minimum has been reached
			</div>
		</template>

		<div ref="chartContainer" class="card-content">
			<highcharts :key="chartKey" :options="chartConfig" class="dash-chart" />
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/SelfLeadershipIcon.vue'
import ChartMixin from '../../Charts/chart-mixin'
import { getLeadingOthersChartOptions, getLeadingOthersChartConfig } from '../../Charts/chart-configs'

const config = [
	{ key: 'for_others', label: 'For Others' },
	{ key: 'feedback', label: 'Feedback' },
	{ key: 'care', label: 'Care' },
	{ key: 'trust', label: 'Trust' },
	{ key: 'micro_management', label: 'Micro Management' },
	{ key: 'skills', label: 'Skills' },
	{ key: 'fully_present', label: 'Fully Present' },
	{ key: 'self_awareness', label: 'Self-Awareness' },
]

const yAxisCategories = [
	{ value: 0, label: '' },
	{ value: 1, label: 'Weakness' },
	{ value: 2, label: 'Proficient' },
	{ value: 3, label: 'Talented' },
	{ value: 4, label: 'Strength' },
]

export default {
    components: {
		DashCard,
		Icon,
	},
	mixins: [ChartMixin],
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
	},
    data () {
        return {
			chartOptions: getLeadingOthersChartOptions(),
		}
    },
    computed: {
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return config.map(item => ({ ...item, value: this.getValue(this.data.data[item.key]) }))
			}
			return config.map(item => ({ ...item, value: 0.01 }))
		},
		chartConfig () {
			const data = [...this.computedData]
				.sort((a, b) => a.value > b.value ? -1 : 1)
				.map(({ label, value }) => ([label, value]))
			return getLeadingOthersChartConfig(data, yAxisCategories, this.chartOptions)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		getValue (value) {
			if (this.checkNullish(value)) { return null }
			return value
		},
		resizeChart (entries) {
			const { width } = entries[0].contentRect
			this.chartOptions = getLeadingOthersChartOptions(width)
			this.chartKey += 1
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-grow: 1;

	.dash-chart {
		width: 100%;
	}
}
</style>
