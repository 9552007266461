<template>
	<DashCard
		title="Agency Dashboard"
		:info-data="infoData"
		info-data-title="Advance: Agency Dashboard"
		without-divider
		centered-title
	>
		<template #title-container>
			<div class="dash-card__title-container">
				<div v-if="!isMobileView && !isTabletView" class="agency-dashboard-filters" />
				<div class="dash-card__title-wrapper justify-center">
					<div class="mr-4 d-flex align-center"><Icon /></div>
					<div class="dash-card__title advance-phase__main-card-title">Agency Dashboard</div>
				</div>
				<div class="agency-dashboard-filters">
					<AgencyLevelSelector
						v-model="agencyLevel"
						:options="agencyLevelOptions"
					/>
				</div>
			</div>
		</template>

		<div class="agency-dashboard-row">
			<AgencyDashboardOverviewCard
				:data="cardsData.overview"
				:selected-agent="selectedAgent || agent"
				:info-data="infoData"
				:options="overviewOptions"
				:server-items-length="serverItemsLength.advance_mad_rollup"
				@update-options="overviewOptions = $event"
				@select-agent="onSelectDownline"
				@reload="reloadData"
			/>

			<AgencyDashboardDownlinesCard
				:data="cardsData.downlines"
				:agent="selectedAgent || agent"
				:info-data="infoData"
				:options="downlinesOptions"
				:server-items-length="serverItemsLength.advance_mad_individual"
				@update-options="downlinesOptions = $event"
				@reload="reloadData"
			/>

			<AgencyDashboardPast12MonthsCard
				:data="cardsData.months"
				:agent="selectedAgent || agent"
				:total-agency="totalAgency"
				:info-data="infoData"
				:options="past12MonthsOptions"
				:server-items-length="serverItemsLength.advance_mad_12month"
				@update-options="past12MonthsOptions = $event"
				@total-agency:update="totalAgency = $event"
				@reload="reloadData"
			/>
		</div>
	</DashCard>
</template>

<script>
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI'
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/AgencyDashboardIcon.vue'
import AgencyDashboardOverviewCard from './AgencyDashboardOverviewCard'
import AgencyDashboardDownlinesCard from './AgencyDashboardDownlinesCard'
import AgencyDashboardPast12MonthsCard from './AgencyDashboardPast12MonthsCard'
import AgencyLevelSelector from '../../Components/AgencyLevelSelector'

import PerformDashMixin from '../../mixins/perform-dash-mixin'
import LeadershipLevelsMixin from '../../mixins/leadership-levels-mixin'
import { agencyLevels, statType, agencyLevelsConfig } from '../../config/common'
import { overviewConfig, downlinesConfig, past12MonthsConfig, getDefaultOptions } from '../../config/agencyDashboard'

const modules = [
	// 'advance_mad_rollup_summary',
	'advance_mad_rollup',
	'advance_mad_individual',
	'advance_mad_12month',
]
// const modulesNeedsDatePeriod = [
// 	// 'advance_mad_rollup_summary',
// 	'advance_mad_rollup',
// 	'advance_mad_individual',
// ]
// const modulesNeedsStatType = [
// 	'advance_mad_12month',
// ]

export default {
    components: {
		DashCard,
		Icon,
		AgencyLevelSelector,
		AgencyDashboardOverviewCard,
		AgencyDashboardDownlinesCard,
		AgencyDashboardPast12MonthsCard,
	},
	mixins: [PerformDashMixin, LeadershipLevelsMixin],
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		agent: Object,
		infoData: Object,
	},
	data () {
		return {
			agencyLevel: agencyLevels.Base,
			selectedAgent: undefined,
			totalAgency: true,

			overviewOptions: getDefaultOptions(overviewConfig),
			downlinesOptions: getDefaultOptions(downlinesConfig),
			past12MonthsOptions: getDefaultOptions(past12MonthsConfig),
			serverItemsLength: {
				advance_mad_rollup: undefined,
				advance_mad_individual: undefined,
				advance_mad_12month: undefined,
			},
		}
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		currentAgent () {
			return this.agent || this.user.Agent
		},
		overviewAgent () {
			return this.selectedAgent || this.currentAgent
		},
		agentCode () {
			return this.currentAgent.AgentCode
		},
		overviewAgentCode () {
			return this.overviewAgent.AgentCode
		},
		modulesAgentCode () {
			return {
				'advance_mad_individual': this.overviewAgentCode,
				'advance_mad_12month': this.overviewAgentCode,
			}
		},
		modulesParams () {
			return {
				// 'advance_mad_rollup_summary': {},
				'advance_mad_rollup': this.overviewOptions,
				'advance_mad_individual': this.downlinesOptions,
				'advance_mad_12month': this.past12MonthsOptions,
			}
		},
		statType () {
			return this.totalAgency ? statType.TotalAgency : statType.Personal
		},
		cardsData () {
			return {
				// summaries: this.modulesData.advance_mad_rollup_summary,
				overview: this.modulesData.advance_mad_rollup,
				downlines: this.modulesData.advance_mad_individual,
				months: this.modulesData.advance_mad_12month,
			}
		},
		leadershipOrderRelativeToAO () {
			const AOIndex = this.sortedLeadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.sortedLeadershipLevels.indexOf(this.currentAgent.LeadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		currentAgentContractLevel () {
			return Number(this.currentAgent.ContractLevel)
		},
		agencyOptionsStaffCase () {
			return this.hasRole(['SuperAdmin', 'Staff'])
		},
		agencyOptionsCase1 () {
			return this.leadershipOrderRelativeToAO < 0
		},
		agencyOptionsCase2 () {
			return this.leadershipOrderRelativeToAO === 0
		},
		agencyOptionsCase3 () {
			return this.leadershipOrderRelativeToAO > 0 && this.currentAgentContractLevel < 130
		},
		agencyOptionsCase4 () {
			return this.leadershipOrderRelativeToAO > 0 && this.currentAgentContractLevel >= 130
		},
		allowBaseOption () {
			return this.agencyOptionsStaffCase || this.agencyOptionsCase2 || this.agencyOptionsCase3 || this.agencyOptionsCase4
		},
		allowDirectOption () {
			return this.agencyOptionsStaffCase || this.agencyOptionsCase4
		},
		allowTotalOption () {
			return this.agencyOptionsStaffCase || this.agencyOptionsCase1 || this.agencyOptionsCase3 || this.agencyOptionsCase4
		},
		agencyLevelOptions () {
			return agencyLevelsConfig.map((item) => {
				let disabled = false
				switch (item.value) {
					case agencyLevels.Base:
						disabled = !this.allowBaseOption
						break
					case agencyLevels.Direct:
						disabled = !this.allowDirectOption
						break
					case agencyLevels.Total:
						disabled = !this.allowTotalOption
						break
					default: disabled = false
				}
				return { ...item, disabled }
			})
		},
		overviewAgentLeadershipOrderRelativeToAO () {
			const AOIndex = this.sortedLeadershipLevels.indexOf('Agency Owner')
			const agentLLIndex = this.sortedLeadershipLevels.indexOf(this.overviewAgent.LeadershipLevel)
			if (agentLLIndex === AOIndex) { return 0 }
			return agentLLIndex > AOIndex ? -1 : 1
		},
		overviewAgentAgencyLevel () {
			return this.overviewAgentLeadershipOrderRelativeToAO < 0 ? agencyLevels.Total : agencyLevels.Base
		},
		modulesLoading () {
			return Object.values(this.modulesData).some((data) => data?.loading)
		},
    },
	watch: {
		modulesLoading () {
			this.$emit('loading', this.modulesLoading)
		},
		monthlyPeriod () {
			this.overviewOptions = { ...this.overviewOptions, page: 1 }
			this.downlinesOptions = { ...this.downlinesOptions, page: 1 }
			// this.loadMadRollupSummary()
			this.loadMadRollup()
			this.loadMadIndividual()
		},
		agencyLevel () {
			this.selectedAgent = undefined
			this.overviewOptions = { ...this.overviewOptions, page: 1 }
			this.downlinesOptions = { ...this.downlinesOptions, page: 1 }
			this.past12MonthsOptions = { ...this.past12MonthsOptions, page: 1 }
			this.updateData()
		},
		totalAgency () {
			this.past12MonthsOptions = { ...this.past12MonthsOptions, page: 1 }
			this.loadMad12Month()
		},
		overviewOptions: {
			deep: true,
			handler () {
				this.loadMadRollup()
			},
		},
		downlinesOptions: {
			deep: true,
			handler () {
				this.loadMadIndividual()
			},
		},
		past12MonthsOptions: {
			deep: true,
			handler () {
				this.loadMad12Month()
			},
		},
	},
	methods: {
		modeWatcherHandler () {
			if (!this.mode.isActive) { return }
			this.selectedAgent = undefined
			this.agencyLevel = (!this.agencyOptionsStaffCase && this.agencyOptionsCase1) ? agencyLevels.Total : agencyLevels.Base
			this.updateData()
		},
		updateData () {
			// this.loadMadRollupSummary()
			this.loadMadRollup()
			this.loadMadIndividual()
			this.loadMad12Month()
		},
		async loadModuleData (module) {
			this.resetModulesState([module])
			this.setModulesLoading([module])
			const agentCode = this.getModuleAgentCode(module)
			const params = this.getModuleParams(module)
			const modulePath = this.addPathDetails(module)
			const res = await QuilityAPI.loadPerformDashModule(modulePath, agentCode, params)
			const isCurrentRequestData = modulePath === this.addPathDetails(module)
			if (!isCurrentRequestData || (res.error && res.text === 'Aborted')) {
				// console.error('Aborted')
			} else {
				const state = { loaded: true }
				if (res.error) {
					state.data = null
					state.loading = false
					state.error = true
				} else {
					state.data = this.processData(res)
					state.loading = false
					state.error = false
				}
				this.processModule(module, res)
				this.modulesData[module] = state
			}
		},
		// loadMadRollupSummary: debounce(async function () {
		// 	await this.loadModuleData('advance_mad_rollup_summary')
		// }, 200),
		loadMadRollup: debounce(async function () {
			await this.loadModuleData('advance_mad_rollup')
		}, 200),
		loadMadIndividual: debounce(async function () {
			await this.loadModuleData('advance_mad_individual')
		}, 200),
		loadMad12Month: debounce(async function () {
			await this.loadModuleData('advance_mad_12month')
		}, 200),
		processModule (module, response) {
			this.serverItemsLength[module] = response?.meta?.total !== 0 ? response?.meta?.total : undefined
		},
		getEmptyModulesData () {
			return modules.reduce((acc, item) => {
				acc[item] = undefined
				return acc
			}, {})
		},
		addPathDetails (module) {
			if (module === 'advance_mad_rollup') {
				return `${module}/${this.agencyLevel}/${this.datePeriod}`
			}
			const agencyLevel = this.selectedAgent ? this.overviewAgentAgencyLevel : this.agencyLevel
			if (module === 'advance_mad_individual') {
				return `${module}/${agencyLevel}/${this.datePeriod}`
			}
			if (module === 'advance_mad_12month') {
				return `${module}/${agencyLevel}/${this.statType}`
			}
			// if (modulesNeedsStatType.includes(module)) {
			// 	return `${module}/${this.agencyLevel}/${this.statType}`
			// }
			// if (modulesNeedsDatePeriod.includes(module)) {
			// 	return `${module}/${this.agencyLevel}/${this.datePeriod}`
			// }
			return module
		},
		onSelectDownline (agent) {
			this.selectedAgent = agent
			this.downlinesOptions = { ...this.downlinesOptions, page: 1 }
			this.past12MonthsOptions = { ...this.past12MonthsOptions, page: 1 }
			this.loadMadIndividual()
			this.loadMad12Month()
		},
	},
}
</script>

<style lang="scss" scoped>
.agency-dashboard-filters {
	width: 8.5rem;
	flex-shrink: 0;
}

.agency-dashboard-row {
	margin-top: 1.5rem;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: 2.5rem;
}

.agency-dashboard-col {
	width: 100%;
}
</style>
