<template>
	<DashCard
		:title="title"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		info-data-title="Advance: Agency Dashboard. Past 12 Months Submit"
		@reload="$emit('reload', 'advance_mad_individual')"
	>
		<template #title-right>
			<v-switch
				v-model="mTotalAgency"
				dark
				hide-details
				inset
				:label="mTotalAgency ? 'Total Agency' : 'Personal'"
				class="past-12-month-switch perform-dash-period-switcher"
			/>
		</template>

		<div class="card-content">
			<v-data-table
				:headers="headers"
				:items="computedData"
				:item-key="itemKey"
				:page.sync="computedOptions.page"
				:items-per-page.sync="computedOptions.itemsPerPage"
				:sort-by.sync="computedOptions.sortBy"
				:sort-desc.sync="computedOptions.sortDesc"
				:server-items-length="serverItemsLength"
				:footer-props="{
					itemsPerPageOptions: [7, 25, 50, 100],
					itemsPerPageText: isMobileView ? '' : 'Rows per page',
				}"
				:hide-default-footer="noLoadedData || !serverItemsLength"
				fixed-header
				dark
				:mobile-breakpoint="0"
				class="advance__agency-dashboard-table"
			>
				<template v-for="h in headers" v-slot:[`item.${h.value}`]="{ value }">
					<span
						v-if="h.value === 'AgentName'" :key="h.value"
						class="nowrap advance__agency-dashboard-table__item"
					>
						{{ value || 'NA' }}
					</span>
					<span v-else :key="h.value">
						<span v-if="Number(value) < 0">-</span>
						<span class="with-currency">{{ formatMoney(value) }}</span>
					</span>
				</template>
			</v-data-table>
		</div>
	</DashCard>
</template>

<script>
import moment from 'moment'

import DashCard from '../DashCard.vue'

import { past12MonthsConfig as config, getEmptyData, getLast12Months } from '../../config/agencyDashboard'

export default {
    components: {
		DashCard,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		totalAgency: {
			type: Boolean,
			default: true,
		},
		options: {
			type: Object,
			required: true,
		},
		serverItemsLength: {
			type: Number,
			default: -1,
		},
		agent: Object,
		infoData: Object,
	},
	data () {
		return {
			itemKey: 'AgentCode',
			valueKey: 'SubmitAPV',
			datePeriodKey: 'BusinessDate',
		}
	},
    computed: {
		computedOptions: {
			get () { return this.options },
			set (v) { this.$emit('update-options', v) },
		},
		mTotalAgency: {
			get () { return this.totalAgency },
			set (val) { this.$emit('total-agency:update', val) },
		},
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return this.data.data.every((item) => {
					if (!item[this.valueKey].length) { return true }
					return item[this.valueKey].every((i) => this.checkNullish(i[this.valueKey]))
				})
			}
			return false
		},
		computedData () {
			let data = getEmptyData(this.itemKey)
			if (this.data && this.data.loaded && !this.noLoadedData) {
				data = this.data.data.map((item) => {
					const values = item[this.valueKey].reduce((acc, i) => {
						acc[i[this.datePeriodKey]] = i[this.valueKey]
						return acc
					}, {})
					return { ...item, ...values }
				})
			}
			return data
		},
		dataDates () {
			if (this.data && this.data.loaded && !this.noLoadedData) {
				return this.getDates(this.data.data)
			}
			return getLast12Months()
		},
		headers () {
			const restConfig = this.dataDates.map((date) => ({
				label: moment(date, 'YYYY-MM').format('MMM`YY'),
				key: date,
			}))
			return [...config, ...restConfig].map((item) => ({
				text: item.label,
                value: item.key,
				sortable: Boolean(item.sortable),
				class: 'nowrap',
			}))
		},
		title () {
			return 'Past 12 Months Submit'
			// return this.agent
			// 	? `Past 12 Months Submit (${this.agent.AgentName})`
			// 	: 'Past 12 Months Submit'
		},
    },
	methods: {
		getDates(data) {
			const item = data.find((i) => i[this.valueKey].length)
			if (!item) { return getLast12Months() }
			return item[this.valueKey].map((i) => i[this.datePeriodKey])
		},
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(Math.abs(value), '0,0[.]00')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

.dash-card {
	margin-left: -1rem;
	margin-right: -1rem;
	padding: 0.5rem 1.5rem 1.5rem;

	// &--mobile {
	// 	padding: 0.5rem 1rem 1.5rem;
	// }

	::v-deep .dash-card__content {
		min-height: 9rem;
	}

	::v-deep .dash-card__header {
		min-height: 2rem;
	}
}

.past-12-month-switch {
	margin-top: 0;
	padding-top: 0;
	min-width: 10.5rem;
}
</style>
